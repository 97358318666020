// Icons
// --------------------------------------------------
$fa-font-path: "../vendor/font-awesome/fonts";

// Colors
$theme-colors: (
    primary: $gray-800,
    //secondary: $gray-600,
    secondary: #656565,
    success: $green,
    info: $cyan,
    warning: $yellow,
    danger: $red,
    light: $gray-100,
    dark: $gray-800
);

$color-theme-environment-ecology:       #02ca2d;
$color-theme-education-equality:        #f2cd00;
$color-theme-enterprise-infrastructure: #2cd2fc;
$color-theme-governance-partnerships:   #ed4e1d;
$color-theme-other:                         #666666;

$color-asset-cash:              #0c6d6a;
$color-asset-equity:            #1ab0aa;
$color-asset-alternatives:      #138f8b;
$color-asset-fixed-income:      #23dcd5;
$color-asset-total:             #000000;

// Body
//
// Settings for the `<body>` element.

$body-bg:       #f5f5f5;
$gray-border-color: #f5f5f5;
$btn-primary-color: #666666;
$btn-coral-color: #f53385;
$body-color:    $gray-800;
$sidebar-bg:    #333333;
$sidebar-color: #1A1A1A;

$font-family-publico: 'PublicoHeadline', 'Times New Roman', serif;
$font-family-tradegothic: 'TradeGothicBoldCondensed', sans-serif;

//$color-pink: #ff3b8e;
$color-pink: rgb(255, 49, 100);
$color-light-grey: #999999;
$color-white: #ffffff;
$color-black: #000000;

// Links
//
// Style anchor elements.

$link-color:            theme-color("primary");
$link-decoration:       underline;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: none;

$font-size-base: 18px;      // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem;
$font-size-sm:   1em;
$font-size-xs:   .75rem;

$font-weight-light: 300;
$font-weight-normal: normal;
$font-weight-bold: bold;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.25rem;
$h4-font-size: 1rem;
$h5-font-size: 0.875rem;
$h6-font-size: 0.75rem;

$display1-size: 4rem;
$display2-size: 3.5rem;
$display3-size: 3rem;
$display4-size: 2.5rem;

// Buttons border radius
$btn-border-radius:          2px;
$btn-border-radius-lg:       4px;
$btn-border-radius-sm:       2px;

$input-btn-padding-y:       .5rem !default;
$input-btn-padding-x:       .875rem !default;
$input-btn-line-height:     1.25 !default;

$input-btn-padding-y-sm:    .25rem !default;
$input-btn-padding-x-sm:    .75rem !default;
$input-btn-line-height-sm:  1.5 !default;

$input-btn-padding-y-lg:    .5rem !default;
$input-btn-padding-x-lg:    1rem !default;
$input-btn-line-height-lg:  1.5 !default;

$btn-font-weight:                $font-weight-normal !default;
$btn-link-disabled-color:        $gray-600 !default;

// Allows for customizing button radius independently from global border radius
//$btn-border-radius:              $border-radius !default;
//$btn-border-radius-lg:           $border-radius-lg !default;
//$btn-border-radius-sm:           $border-radius-sm !default;
